.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  // display: flex;
  justify-content: center;
  align-items: center;
}
.app-footer {
  .navbar {
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 4.286rem;
    background-color: transparent;
    z-index: 11;
    padding: 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    font-size: 12px;
  }
  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    padding: 0 0.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .nav-link {
    display: initial;
    color: inherit;
  }
}
.no-change-link {
  a:hover {
    svg {
      color: #626262 !important;
    }
  }
}
