//ダークモードとかに影響されない色変数
$primary: #584d82;
$secondary: #2f75d1;
$success: #5cad66;
$info: #52c4de;
$notebox: #ff6a14;
$warning: #e6990d;
$danger: #c34b4b;
$dark: rgb(43, 44, 48);
$light: rgb(240, 240, 240);
//jsで読み込めるcss変数を代入しておく
:root {
  --color-theme: rgb(255, 106, 20);
  --color-base: white;
  --color-main: black;
  --color-bg: rgb(255, 251, 248);
}
//css変数を他のscssに渡す用
$C_THEME: var(--color-theme);
$C_THEME_DARKMODE: #ff8a46;
$C_BASE: var(--color-base);
$C_MAIN: var(--color-main);
$C_BG: var(--color-bg);

$card-border-width: 0;
$border-radius: 4px;
$border-color: rgba($dark, 0.05);
$list-group-border-color: $border-color;
$spacer: 1.143rem;
$grid-gutter-width: $spacer;
