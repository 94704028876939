@import "variables";
.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: $C_THEME;
  text-decoration: none;
}
.dark .title a {
  color: $C_THEME_DARKMODE;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin-top: 2rem;
  line-height: 1.15;
  font-size: 2.7rem;
  font-weight: 700;
  @media (max-width: 900px) {
    font-size: 1.8rem;
  }
}

.description {
  line-height: 1.5;
  font-size: 1.3rem;
  @media (max-width: 900px) {
    font-size: 1rem;
  }
}

.grid {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  @media (max-width: 900px) {
    margin-top: 0rem;
    width: 100%;
    flex-direction: column;
  }
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: $C_THEME;
  border-color: $C_THEME;
}
.dark {
  .card:hover,
  .card:focus,
  .card:active {
    color: $C_THEME_DARKMODE;
    border-color: $C_THEME_DARKMODE;
  }
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}
